<script setup lang="ts">
import type { Placement } from '@floating-ui/vue'
import {
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useFloating,
} from '@floating-ui/vue'
import { onClickOutside } from '@vueuse/core'
import type { Ref } from 'vue'
import { inject } from 'vue'
import { ref } from '#imports'

const props = defineProps<{
  offset?: number
  placement?: Placement
}>()

const emit = defineEmits(['click-outside'])
const anchor = inject<Ref<Element>>('anchor')

const menuRef = ref<HTMLElement>()

const maxDimensions = ref({
  maxHeight: '100%',
  maxWidth: '100%',
})

const { isPositioned, floatingStyles } = useFloating(anchor!, menuRef, {
  placement: props.placement || 'bottom-end',
  middleware: [
    flip(),
    offset(props.offset || 0),
    shift({ padding: 8 }),
    size({
      padding: 16,
      apply: ({ availableWidth, availableHeight }) => {
        maxDimensions.value = {
          maxHeight: `${Math.max(100, availableHeight)}px`,
          maxWidth: `${availableWidth}px`,
        }
      },
    }),
  ],
  whileElementsMounted: autoUpdate,
})

onClickOutside(menuRef, (e) => {
  emit('click-outside', e)
})

defineSlots<{
  default(props: { maxDimensions: (typeof maxDimensions)['value'] }): void
}>()
</script>

<template>
  <dialog
    ref="menuRef"
    v-bind="$attrs"
    class="z-100 card bg-surface relative m-0 transform-gpu overflow-hidden rounded-md shadow-md transition-opacity duration-300"
    :class="{
      'opacity-0': !isPositioned,
    }"
    :style="{ ...floatingStyles, ...maxDimensions }"
    open
  >
    <div class="w-max max-w-sm">
      <slot v-bind="{ maxDimensions }" />
    </div>
  </dialog>
</template>
